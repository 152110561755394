import { template as template_69c7cfd9abd147f6b0d9e98e49386fc3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_69c7cfd9abd147f6b0d9e98e49386fc3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
