import { template as template_0e457beaa863493a8883fa97c3146da4 } from "@ember/template-compiler";
const FKLabel = template_0e457beaa863493a8883fa97c3146da4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
