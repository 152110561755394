import { template as template_44264f8b87b44a49904cb749e774cdde } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_44264f8b87b44a49904cb749e774cdde(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
